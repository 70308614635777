<template>
	<div>
		<a-spin :spinning="loading">
			<span @click="onShowModal">
				<slot>
					<a-button type="primary">添加政策</a-button>
				</slot>
			</span>
			<a-modal v-model:visible="visible" width="850px" title="添加政策" @cancel="onCancel">
				<template #footer>
					<a-button @click="onCancel">取消</a-button>
					<a-button v-if="!disabled" @click="onOk(false)" type="primary">添加并返回</a-button>
					<!-- <a-button @click="onOk(true)" type="primary">添加并继续</a-button> -->
				</template>
				<a-spin :spinning="loading">
					<a-form ref="formRef" :model="modelRef" scrollToFirstError name="formRef" 
						:labelCol="{span: 4 }"
						:wrapperCol="{span: 18 }"
					>
						
						<div v-if="type === 'preferential'">
							<a-form-item label="影院组织" name="organizationId">
								<a-select :disabled="disabled" v-model:value="modelRef.organizationId" allowClear placeholder="请选择影院组织" show-search :filter-option="filterOption" @change="getCinemaList">
									<a-select-option v-for="item in organizationList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
								</a-select>
							</a-form-item>
							
							<a-form-item label="所属影院" name="cinemaId" extra="选择所属影院后开启其它选择项">
								<a-select :disabled="disabled" v-model:value="modelRef.cinemaId" allowClear placeholder="请选择所属影院" show-search :filter-option="filterOption" @change="onCinemaChange">
									<a-select-option v-for="item in cinemaAllList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
								</a-select>
							</a-form-item>
						</div>
						
						<a-form-item label="政策名称" name="pricingName" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-input :disabled="disabled" v-model:value="modelRef.pricingName" placeholder="请输入政策名称"></a-input>
						</a-form-item>
						
						<a-row>
							<a-col :offset="1" :span="22">
								<a-row style="padding-left:10px;">
									<a-col :span="7">
										<a-form-item label="优先级别" name="pricingLevel" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 10 }" :wrapperCol="{span: 14 }">
											<a-input-number :disabled="disabled" v-model:value="modelRef.pricingLevel" :min="0" :precision="0" placeholder="请输入"></a-input-number>
										</a-form-item>
									</a-col>
									<a-col :span="7" v-if="!['yearCard'].includes(type)">
										<a-form-item v-if="type === 'stored'" label="结算金额" name="deductionAmount" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 10 }" :wrapperCol="{span: 14 }">
											<a-input-number :disabled="disabled" v-model:value="modelRef.deductionAmount" :min="0" :precision="2" placeholder="请输入"></a-input-number>
										</a-form-item>
										<a-form-item v-else label="补差金额" name="diffPrice" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 10 }" :wrapperCol="{span: 14 }">
											<a-input-number :disabled="disabled" v-model:value="modelRef.diffPrice" :min="0" :precision="2" placeholder="请输入"></a-input-number>
										</a-form-item>
									</a-col>
									<a-col :span="7">
										<a-form-item label="是否启用" name="isDisabled" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 10 }" :wrapperCol="{span: 14 }">
											<a-switch :disabled="disabled" v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
										</a-form-item>
									</a-col>
								</a-row>
							</a-col>
						</a-row>
						
						<a-row v-if="!['yearCard', 'preferential', 'tiktok'].includes(type)">
							<a-col :offset="1" :span="22">
								<a-row style="padding-left:10px;">
									<a-col :span="7">
										<a-form-item label="场次限购" name="singleCount" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 10 }" :wrapperCol="{span: 14 }">
											<a-input-number :disabled="disabled" v-model:value="modelRef.singleCount" :min="0" :precision="0" placeholder="请输入"></a-input-number>
										</a-form-item>
									</a-col>
									<a-col :span="7">
										<a-form-item label="影片限购" name="filmCount" :rules="[{required: true, message: '必填项不允许为空'}]" :labelCol="{span: 10 }" :wrapperCol="{span: 14 }">
											<a-input-number :disabled="disabled" v-model:value="modelRef.filmCount" :min="0" :precision="0" placeholder="请输入"></a-input-number>
										</a-form-item>
									</a-col>
								</a-row>
							</a-col>
						</a-row>
						
						<p v-if="!['yearCard', 'preferential', 'tiktok'].includes(type)" style="text-align: center;color:red;">
							 场次限购：限制场次限购票数（单场次限购数）。 
						</p>
						
						<p v-if="!['yearCard', 'preferential', 'tiktok'].includes(type)" style="text-align: center;color:red;">
							  影片限购：限制影片限购票数（每部影片限购数）
						</p>
						
						<a-form-item label="规则类型" name="versionType" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-radio-group :disabled="disabled" v-if="['yearCard'].includes(type)" v-model:value="modelRef.versionType" button-style="solid" >
								<a-radio-button :value="3">批量设置</a-radio-button>
								<a-radio-button :value="4">场次设置</a-radio-button>
							</a-radio-group>
							
							<a-radio-group :disabled="disabled" v-else v-model:value="modelRef.versionType" button-style="solid">
								<a-radio-button :value="3">批量定价</a-radio-button>
								<a-radio-button :value="4" :disabled="type === 'preferential' && !modelRef.cinemaId">场次定价</a-radio-button>
							</a-radio-group>
						</a-form-item>
						
						<div v-if="modelRef.versionType === 3">
							<a-form-item label="影片规则" :rules="[{required: true, message: '必填项不允许为空'}]">
								<div class="ui-flex">
									<a-radio-group :disabled="disabled" v-model:value="modelRef.filmType" button-style="solid" @change="modelRef.filmList = [];movieList = [];">
										<a-radio-button :value="1">不限制</a-radio-button>
										<a-radio-button :value="2" :disabled="type === 'preferential' && !modelRef.cinemaId">指定可用影片</a-radio-button>
										<a-radio-button :value="3" :disabled="type === 'preferential' && !modelRef.cinemaId">指定不可用影片</a-radio-button>
									</a-radio-group>
									<span v-if="modelRef.filmType !== 1" style="margin-left: 20px;">
										<movie :id="type === 'preferential' ? modelRef.cinemaId : id" :disabled="disabled" v-model:value="modelRef.filmList" @change="(dataList) => { movieList = dataList;modelRef.movieList = dataList; }"></movie>
									</span>
								</div>
								<div v-if="movieList.length" style="margin: 10px 0;">
									<a-table rowKey="id" :columns="movieColumns" :dataSource="movieList" size="small" :pagination="false">
										<template #bodyCell="{ column, record, index }">
											<template v-if="column.key === 'action'">
												<a-button :disabled="disabled" type="link" @click="onDeleteMovie(record, index)">删除</a-button>
											</template>
										</template>
									</a-table>
								</div>
							</a-form-item>
						
							<a-form-item label="影厅规则" :rules="[{required: true, message: '必填项不允许为空'}]">
								<div class="ui-flex">
									<a-radio-group :disabled="disabled" v-model:value="modelRef.hallType" button-style="solid" @change="modelRef.hallList = [];hallDataList = [];">
										<a-radio-button :value="1">不限制</a-radio-button>
										<a-radio-button :value="2" :disabled="type === 'preferential' && !modelRef.cinemaId">指定可用影厅</a-radio-button>
										<a-radio-button :value="3" :disabled="type === 'preferential' && !modelRef.cinemaId">指定不可用影厅</a-radio-button>
									</a-radio-group>
									<span v-if="modelRef.hallType !== 1" style="margin-left: 20px;">
										<cinema :disabled="disabled" :id="type === 'preferential' ? modelRef.cinemaId : id" v-model:value="modelRef.hallList" @change="(dataList) => {hallDataList = dataList; modelRef.hallDataList = dataList;}"></cinema>
									</span>
								</div>
								<div v-if="hallDataList.length" style="margin: 10px 0;">
									<a-table rowKey="id" :columns="hallColumns" :dataSource="hallDataList" size="small" :pagination="false">
										<template #bodyCell="{ column, record, index }">
											<template v-if="column.key === 'action'">
												<a-button :disabled="disabled" type="link" @click="onDeleteHall(record, index)">删除</a-button>
											</template>
										</template>
									</a-table>
								</div>
							</a-form-item>
						
							<a-form-item label="视觉规则" name="visualType" :rules="[{required: true, message: '必填项不允许为空'}]">
								<a-radio-group :disabled="disabled" v-model:value="modelRef.visualType" button-style="solid" @change="modelRef.dimensionalList = []">
									<a-radio-button :value="1">不限制</a-radio-button>
									<a-radio-button :value="2">指定可用视觉</a-radio-button>
									<a-radio-button :value="3">指定不可用视觉</a-radio-button>
								</a-radio-group>
							</a-form-item>
							
							<a-form-item label="公共假期" name="isHoliday" :rules="[{required: true, message: '必填项不允许为空'}]">
								<a-switch :disabled="disabled" v-model:checked="modelRef.isHoliday" checked-children="可用" un-checked-children="不可用" />
							</a-form-item>
						
							<a-form-item v-if="modelRef.visualType !== 1" label="选择视觉" name="dimensionalList" :rules="[{required: true, message: '必选项不允许为空'}]">
								<a-form-item-rest>
									<a-checkbox :disabled="disabled" v-model:checked="checkAll" :indeterminate="indeterminate" @change="onCheckAllChange">全选</a-checkbox>
								</a-form-item-rest>
								<a-checkbox-group :disabled="disabled" v-model:value="modelRef.dimensionalList">
									<a-checkbox :value="1">2D</a-checkbox>
									<a-checkbox :value="2">3D</a-checkbox>
									<a-checkbox :value="3">4D</a-checkbox>
									<a-checkbox :value="4">IMAX2D</a-checkbox>
									<a-checkbox :value="5">IMAX3D</a-checkbox>
								</a-checkbox-group>
							</a-form-item>
						
							<a-form-item label="放映时间">
								<div class="ui-time__header">
									<span class="ui-time__span">日期</span>
									<a-date-picker :disabled="disabled" v-model:value="modelRef.startDate" />
									<span class="ui-time__span">至</span>
									<a-date-picker :disabled="disabled" v-model:value="modelRef.endDate" />
									<a class="ui-time__span" @click="onAddSet">
										<Icon icon="PlusOutlined" style="margin-right: 2px;"></Icon>添加适用时间段
									</a>
								</div>
								<div class="ui-time__set" v-for="(item,index) in modelRef.timeList" :key="index">
									<div>
										<span class="ui-time__span">周期</span>
										<a-checkbox-group :disabled="disabled" v-model:value="item.week">
											<a-checkbox :value="7">周日</a-checkbox>
											<a-checkbox :value="1">周一</a-checkbox>
											<a-checkbox :value="2">周二</a-checkbox>
											<a-checkbox :value="3">周三</a-checkbox>
											<a-checkbox :value="4">周四</a-checkbox>
											<a-checkbox :value="5">周五</a-checkbox>
											<a-checkbox :value="6">周六</a-checkbox>
										</a-checkbox-group>
									</div>
									<div class="ui-margin__top20">
										<span class="ui-time__span">时段</span>
										<a-time-picker :disabled="disabled" format="HH:mm" v-model:value="item.startTime" />
										<span class="ui-time__span">至</span>
										<a-time-picker :disabled="disabled" format="HH:mm" v-model:value="item.endTime" />
										<a class="ui-time__span" @click="onDelSetting(index)">
											<Icon icon="DeleteOutlined"></Icon> 删除
										</a>
									</div>
								</div>
								<div style="color:red;">
									注：如存在多条放映时间段，以重叠的时间段为准
								</div>
							</a-form-item>
						</div>
						<div v-else-if="modelRef.versionType === 4">
							<a-form-item label="场次" name="showList">
								<a-form-item-rest>
									<screen :disabled="disabled" :id="type === 'preferential' ? modelRef.cinemaId : id" v-model:value="modelRef.showList"></screen>
								</a-form-item-rest>
							</a-form-item>
						</div>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	// import dayjs from 'dayjs';
	import { Icon } from '@/components/icon/icon.js';
	import screen from '@/components/screen/index.vue';
	import movie from '@/components/movie/index.vue';
	import cinema from '@/components/cinema/index.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	export default {
		components: {Icon, screen, movie, cinema},
		model: {
			prop: 'value'
		},
		props: {
			value: {
				type: null
			},
			type: {
				type: String,
				default: ''
			},
			id: { // 影院ID
				type: null,
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			'modelRef.dimensionalList'(newVal) {
				this.indeterminate = !!newVal.length && newVal.length < 5;
				this.checkAll = newVal.length === 5;
			}
		},
		created() {
			if (this.value) {
				this.modelRef = this.$deepClone(this.value);
				if (this.modelRef.movieList && this.modelRef.movieList.length) {
					this.movieList = this.modelRef.movieList;
					delete this.modelRef.movieList;
				}
				if (this.modelRef.hallDataList && this.modelRef.hallDataList.length) {
					this.hallDataList = this.modelRef.hallDataList;
					delete this.modelRef.hallDataList;
				}
			}
		},
		data() {
			return {
				loading: false,
				visible: false,
				checkAll: false,
				indeterminate: false,
				organizationList: [],
				cinemaAllList: [],
				modelRef: {
					versionType: 3,
					isDisabled: true,
					filmType: 1,
					filmList: [],
					hallType: 1,
					hallList: [],
					isHoliday: false,
					visualType: 1,
					dimensionalList: [],
					showList: [],
					startDate: null,
					endDate: null,
					timeList: []
				},
				settingList: [],
				movieList: [],
				movieColumns: [{
					title: '影片编码',
					dataIndex: 'code'
				}, {
					title: '影片名称',
					dataIndex: 'shortName'
				},{
					title: '语言',
					dataIndex: 'language',
					width: 80,
					align: 'center'
				}, {
					title: '操作',
					key: 'action'
				}],
				hallDataList: [],
				hallColumns: [{
					title: '影厅编码',
					dataIndex: 'code'
				}, {
					title: '影厅名称',
					dataIndex: 'name'
				}, {
					title: '操作',
					key: 'action'
				}]
			}
		},
		methods: {
			onShowModal() {
				if (this.type !== 'preferential' && !this.id) {
					return this.$message.warn('请先选择所属影院');
				}
				if (this.type === 'preferential') {
					this.getOrganizationList();
					if (this.value.cinemaId) {
						this.getCinemaList(this.value.organizationId)
					} else {
						this.value.organizationId = undefined;
						this.value.cinemaId = undefined;
					}
				}
				this.visible = true;
				if (this.value) {
					this.modelRef = this.$deepClone(this.value);
					if (this.modelRef.movieList && this.modelRef.movieList.length) {
						this.movieList = this.modelRef.movieList;
						//delete this.modelRef.movieList;
					} else {
						this.movieList = [];
					}
					if (this.modelRef.hallDataList && this.modelRef.hallDataList.length) {
						this.hallDataList = this.modelRef.hallDataList;
						//delete this.modelRef.hallDataList;
					} else {
						this.hallDataList = [];
					}
				}
			},
			onCancel() {
				this.visible = false;
				if (this.value) {
					this.modelRef = this.$deepClone(this.value);
					if (this.modelRef.movieList && this.modelRef.movieList.length) {
						this.movieList = this.modelRef.movieList;
						delete this.modelRef.movieList;
					}
					if (this.modelRef.hallDataList && this.modelRef.hallDataList.length) {
						this.hallDataList = this.modelRef.hallDataList;
						delete this.modelRef.hallDataList;
					}
				} else {
					this.movieList = [];
					this.hallDataList = [];
					this.modelRef= {
						versionType: 3,
						isDisabled: true,
						filmType: 1,
						filmList: [],
						hallType: 1,
						hallList: [],
						isHoliday: false,
						visualType: 1,
						dimensionalList: [],
						showList: [],
						startDate: null,
						endDate: null,
						timeList: []
					}
				}
			},
			onOk(type) {
				this.$refs.formRef.validateFields().then(() => {
					if (this.modelRef.versionType === 3) {
						if (this.modelRef.filmType !== 1 && this.modelRef.filmList.length === 0) {
							this.$message.error('影片列表不能为空，请选择影片！')
							return;
						}
						if (this.modelRef.hallType !== 1 && this.modelRef.hallList.length === 0) {
							this.$message.error('影厅列表不能为空，请选择影厅！')
							return;
						}
					}
					let data = this.$deepClone(this.modelRef);
					if (this.type === 'preferential'){
						data.singleCount = 0;
						data.filmCount = 0;
					};
					this.$emit('update:value', data);
					this.$emit('ok', data);
					if (this.value) {
						this.modelRef = this.modelRef = this.$deepClone(this.value);
					} else {
						this.modelRef= {
							versionType: 3,
							isDisabled: true,
							filmType: 1,
							filmList: [],
							hallType: 1,
							hallList: [],
							isHoliday: false,
							visualType: 1,
							dimensionalList: [],
							showList: [],
							startDate: null,
							endDate: null,
							timeList: []
						}
					}
					this.visible = type;
				});
			},
			onAddSet() {
				if (this.disabled) return;
				this.modelRef.timeList.push({
					week: [7, 1, 2, 3, 4, 5, 6],
					startTime: this.moment('00:00', 'HH:mm'),
					endTime: this.moment('23:59', 'HH:mm')
				})
			},
			onDelSetting(index) {
				if (this.disabled) return;
				this.modelRef.timeList.splice(index, 1);
			},
			onCheckAllChange(e) {
				if (e.target.checked) {
					this.modelRef.dimensionalList = [1,2,3,4,5];
				} else {
					this.modelRef.dimensionalList = [];
				}
			},
			onDeleteMovie(item, index) {
				let i = this.modelRef.filmList.indexOf(item.id);
				this.modelRef.filmList.splice(i, 1);
				this.movieList.splice(index, 1);
			},
			onDeleteHall(item, index) {
				let i = this.modelRef.hallList.indexOf(item.id);
				this.modelRef.hallList.splice(i, 1);
				this.hallDataList.splice(index, 1);
			},
			onOrganizationChange(id) {
				this.onCinemaChange();
				this.getCinemaList(id);
			},
			onCinemaChange(cinemaId) {
				this.$message.warn('您已切换影院，请重新设置政策');
				this.modelRef.filmType = 1;
				this.modelRef.filmList = [];
				this.movieList = [];
				this.modelRef.hallType = 1;
				this.modelRef.hallList = [];
				this.hallDataList = [];
				this.modelRef.showList = [];
				if (!cinemaId) {
					this.modelRef.versionType = 3;
				}
			},
			filterOption(input, option) {
				return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.modelRef.cinemaId = undefined;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
		}
	}
</script>

<style scoped>
	.ui-flex {
		display: flex;
	}
	
	.ui-input {
		width: 180px;
		display: inline-block;
	}
	
	.ui-margin__top20 {
		margin-top: 20px;
	}
	
	.ui-margin__top40 {
		margin-top: 40px;
	}
	
	.ui-time__header {
		padding: 10px;
		color: rgba(0, 0, 0, .65);
		background-color: #f5f5f5;
	}
	
	.ui-time__span {
		padding: 0 10px;
	}
	
	.ui-time__set {
		padding: 20px 10px;
		border-bottom: solid 1px #eee;
		border-left: solid 1px #eee;
		border-right: solid 1px #eee;
	}
</style>
